import React, { type PropsWithChildren, type FC, type JSX } from 'react';
import { useTranslation, Text, Amount } from 'bb/i18n';
import { ProfilesIcon, BookIcon, LogOutIcon, Stack, Gap } from 'bb/ui';
import css from './subscriptionProductsFooter.module.scss';
import { type SubscriptionProducts } from './types';

type ItemProps = {
    icon: JSX.Element;
    t?: string;
};

export const Item: FC<PropsWithChildren<ItemProps>> = ({
    children,
    icon,
    t
}) => (
    <Gap alignItems="center" spacing={2} direction="row" className={css.item}>
        {icon}
        <Text t={t} as="span">
            {children}
        </Text>
    </Gap>
);

type ContentProps = {
    familyT?: string;
    headingT?: string;
    subscriptionProducts?: SubscriptionProducts;
};

export const SubscriptionProductsFooter: FC<
    PropsWithChildren<ContentProps>
> = ({ children, familyT, headingT, subscriptionProducts }) => {
    const { t } = useTranslation('subscription');

    return (
        <>
            <Text
                as="h3"
                t={headingT ?? 'subscription:includedInAllPackages'}
                center
                size="large"
            />
            <Stack
                alignItems="center"
                justifyContent="center"
                className={css.container}
            >
                {/* TODO: Make more flexible and remove default "children" */}
                {children ?? (
                    <>
                        <Item
                            icon={<BookIcon inline />}
                            t="subscription:accessAllBooks"
                        />
                        <Item
                            icon={<LogOutIcon inline />}
                            t="common:quitWhenYouWant"
                        />
                    </>
                )}
                {!subscriptionProducts?.profilePriceDifference && (
                    <Item icon={<ProfilesIcon inline />}>
                        {t(
                            (familyT as Parameters<typeof t>[0]) ??
                                'subscription:familyAccount',
                            {
                                pricePerProfile: (
                                    <Amount
                                        amount={
                                            subscriptionProducts?.profilePrice
                                        }
                                        key="pricePerProfile"
                                    />
                                )
                            }
                        )}
                    </Item>
                )}
            </Stack>
        </>
    );
};
