import React from 'react';
import classnames from 'classnames';
import commonCss from 'bb/style/common.module.scss';
import { Box, type BoxProps } from 'bb/ui';

export type SectionProps = {
    withPagePadding?: boolean;
} & BoxProps<'section'>;

export const Section = ({
    className,
    children,
    withPagePadding,
    fluid = true,
    ref,
    ...restProps
}: SectionProps) => (
    <Box
        as="section"
        ref={ref}
        className={classnames(className, {
            [commonCss.pagePadding ?? '']: withPagePadding
        })}
        fluid={fluid}
        {...restProps}
    >
        {children}
    </Box>
);
